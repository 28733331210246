// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyC4F9cU_YuxT0OFPUZ2osj6LZ_BnQ89kko",
  authDomain: "metalart-bf787.firebaseapp.com",
  projectId: "metalart-bf787",
  storageBucket: "metalart-bf787.appspot.com",
  messagingSenderId: "264639942866",
  appId: "1:264639942866:web:d45395bfc7e1105d5d1d0b"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
import React from 'react'

const About = () => {
  return (
    <div className='antialiased bg-gray-100'>
    <div className='flex w-auto min-h-screen justify-center items-center text-black text-3xl'>
      <p>
            Welcome to metal art dessign ltd. You can enjoy our handmade products like bespoke coffee tables, beds , garden furniture and BBQs. You can trust our experience .
Olso we offer services in your place for repair garage doors, gates, house doors. Feel free to contact us!
            </p>
    </div>
    </div>
  )
}

export default About
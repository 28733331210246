import React , {useState}  from 'react'
import Logo from "../assets/Logo.png"
import cartImage from "../assets/cartImage.jpg"
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux/es/hooks/useSelector'
import {FaBars, FaTimes } from 'react-icons/fa'
const Header = () => {
    const productData = useSelector((state) => state.metal.productData);
    const userInfo = useSelector((state) => state.metal.userInfo);
    const [isOpen , setisOpen] = useState(false)
  
  return (
    <div className='shadow-md w-full bg-white fixed top-0 left-0 z-50'>
        <div className='sm:px-10 py-4 px-7 sm:flex flex items-center justify-between bg-white'>
            
            <div className='flex items-center'>
                <img className="w-12 h-12" src={Logo} alt="Logo" />
            </div>          
            <div onClick={() => setisOpen(!isOpen)} className='w-7 h-7 absolute right-8 top-6 cursor-pointer sm:hidden'>
              {
                isOpen ? <FaTimes /> : <FaBars />
              }
            </div>
            
                <ul className={` sm:flex text-2xl pl-9 sm:static sm:pl-0 sm:items-center sm:pb-0 pb-12 absolute sm:z-auto z-[-1] left-0 w-full transition-all bg-white duration-500 ease-in ${isOpen ? 'top-12' : 'top-[-490px]'} `}>
                    <Link to="/">
                    <li className='my-7 sm:my-0 sm:ml-8 text-black font-semibold hover:text-gray-400 hover:underline undeline-offset-2 decoration-[3px] cursor-pointer duration-300'>Home</li>
                    </Link>
                    <Link to="./about">
                    <li className='my-7 sm:my-0 sm:ml-8 text-black font-bold hover:text-gray-400 hover:underline undeline-offset-2 decoration-[3px] cursor-pointer duration-300'>Services</li>
                    </Link>
                    <Link to="/">
                    <li className='my-7 sm:my-0 sm:ml-8 text-black font-bold hover:text-gray-400 hover:underline undeline-offset-2 decoration-[3px] cursor-pointer duration-300'>Shop</li>
                    </Link>
                    <Link to="/contact">
                    <li className='my-7  sm:my-0 sm:ml-8 text-black font-bold hover:text-gray-400 hover:underline undeline-offset-2 decoration-[3px] cursor-pointer duration-300'>Contact</li>
                    </Link>
                
        
        </ul>
        </div>   
    </div>

  )
}

export default Header
import React, { useEffect , useState } from 'react'
import { MdOutlineStar } from "react-icons/md"
import { useDispatch } from 'react-redux'
import { useLocation} from "react-router-dom"
import { addToCart } from '../redux/metalArtDesignSlice'
import { ToastContainer, toast } from 'react-toastify'
const Product = () => {
    const dispatch = useDispatch()
    const [details, setDetails] = useState({});
    let [baseQty, setBaseQty] = useState(1);
    const location = useLocation();
    useEffect(()=> {
       setDetails(location.state.item);
    },[]);
  return <div>
    <div className='max-w-screen-xl mx-auto my-10 flex gap-10'>
        <div className='w-2/5 relative'>
            <img
            className='w-full h-[550px] object-cover'
            src={details.image} 
            alt="productImg"
            />
        </div>
        <div className='w-3/5 flex flex-col justify-center'>
            <div>
                <h2 className='text-4xl text-semibold'>{details.title}</h2>
            </div>
            
            <div className='flex items-center gap-2 text-base'>
             <div className='flex'>
                <MdOutlineStar />
                <MdOutlineStar />
                <MdOutlineStar />
                <MdOutlineStar />
                <MdOutlineStar />
              </div>
              <p className='text-xs text-gray-500 '>(1 Customer review)</p>
            </div>
            <p className='text-base text-gray-500 mt-3'>{details.description}</p>
            
        </div>
    </div>
    <ToastContainer
    position="top-left"
    autoClose={2000}
    hideProgressBar={false}
    newestOnTop={false}
    closeOnClick
    rtl={false}
    pauseOnFocusLoss
    draggable
    pauseOnHover
    theme="dark"
    />
  </div>
  
}

export default Product